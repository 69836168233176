import { observer } from 'mobx-react-lite'
import React from 'react'
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon, styled, Divider, ThemeProvider } from "@mui/material";
import { LocalShipping, CheckCircle, Inventory } from "@mui/icons-material";
import { ReactComponent as StartDelivery } from './Assets/StartDelivery.svg'
import { ReactComponent as DeliveryOnProcess } from './Assets/DeliveryOnProcess.svg'
import { ReactComponent as SuccessDelivery } from './Assets/SuccessDelivery.svg'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate';
import { MainTemplate } from 'Components/Template/MainTemplate';

const OrderTracking = observer(({ dataTracking }) => {
    const translate = useTranslate()
    const navigate = useNavigate()

    const formatDate = (dateStr) => {
        const [day, month, year] = dateStr.split("/").map(Number)
        const date = new Date(year, month - 1, day)

        return date.toLocaleDateString(StoreTranslate.CurrentLanguage() === 'TH' ? "th-TH" : "en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        }).replace(" ", "-").replace(" ", "-")
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <TypographyStyled fontWeight={700} fontSize={'32px !important'} marginBottom={'32px'} color={'#003B6F'}>
                {translate('order_tracking')}
            </TypographyStyled>
            <Stack 
                sx={{  
                    borderBottom: '1px solid #CCDEEF', 
                    paddingBottom: '32px',
                    flexDirection: 'column',
                    '@media screen and (min-width: 530px)': {
                        flexDirection: 'row',
                    }
                }}>
                <TypographyStyled fontWeight={400} color={'#003B6F'} fontSize={'20px !important'} >
                    {translate('tracking_number')}:&nbsp; 
                </TypographyStyled>
                <TypographyStyled fontWeight={400} fontSize={'20px !important'} color={'#5A8FC3'} sx={{ textDecorationLine: 'underline' }}>
                    {dataTracking?.responseObject?.do_no}
                </TypographyStyled>
            </Stack>
            <Stack 
                sx={{
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '42px 0px',
                    flexDirection: 'column',
                    width: 'fit-content',
                    '@media screen and (min-width: 600px)': {
                        padding: '42px 20px 20px 20px',
                        margin: '0 auto',
                    }

                }}
            >
                {dataTracking.responseObject?.internal_trans?.map((item, index) => {
                    let lastStep = index === 0 && item.current_step
                    let firstStep = index === dataTracking.responseObject?.internal_trans.length - 1
                    let iconStatus = dataTracking.responseObject?.do_trans[index]?.icon_status

                    return (
                        <Stack key={index} 
                            sx={{ 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                width: 'fit-content', 
                                margin: '-1px 0px' 
                            }}
                        >
                            <Stack 
                                padding={lastStep ? '12px 24px 0px 24px' : firstStep ? '0px 24px 45px 24px' : '0px 24px'}
                                sx={{
                                    padding: lastStep ? '12px 20px 0px 15px' : firstStep ? '0px 20px 45px 15px' : '0px 20px 0px 15px',
                                    '@media screen and (min-width: 600px)': {
                                        padding: lastStep ? '12px 24px 0px 24px' : firstStep ? '0px 24px 45px 24px' : '0px 24px',
                                    }
                                }}
                            >
                                {lastStep === false ? (
                                    <Divider 
                                        id="upperLine" 
                                        sx={{ 
                                            borderLeft: "2px dashed #ccc",
                                            minHeight: "48px", 
                                            background: 'none', 
                                            margin: '-1px auto',
                                            '@media screen and (min-width: 600px)': {
                                                margin: '0 auto',
                                                minHeight: "40px", 
                                            }
                                        }} 
                                    />
                                ) : null}

                                <Stack minWidth={'35px'}>
                                    {lastStep && iconStatus === 70 && (
                                        <SuccessDelivery
                                            style={{ 
                                                width: '35px',
                                                height: '35px',
                                            }} 
                                        />
                                    )}
                                    {!firstStep && !lastStep && (
                                        <DeliveryOnProcess 
                                            style={{ 
                                                width: '35px',
                                                height: '35px',
                                            }} 
                                        />
                                    )}
                                    {firstStep && (
                                        <StartDelivery 
                                            style={{ 
                                                width: '35px',
                                                height: '35px',
                                            }} 
                                        />
                                    )}
                                </Stack>
                                
                                {!firstStep && (
                                    <Divider 
                                        id="lowerLine" 
                                        sx={{ 
                                            borderLeft: "2px dashed #ccc",
                                            minHeight: "48px", 
                                            background: 'none', 
                                            margin: '-1px auto',
                                            '@media screen and (min-width: 600px)': {
                                                margin: '0 auto',
                                                minHeight: "40px", 
                                            }
                                        }} 
                                    />
                                )}
                            </Stack>
                            <Stack>
                                <TypographyStyled
                                    sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                                    fontWeight={400} fontSize={'16px !important'} color={item.current_step ? '#003B6F' : '#8A9BB0'}>
                                    {formatDate(item.track_date)} 
                                    <TypographyStyled fontWeight={400} fontSize={'16px !important'} color={item.current_step ? '#003B6F' : '#8A9BB0'}>
                                        {item.track_time}
                                    </TypographyStyled>
                                </TypographyStyled>
                                <TypographyStyled fontWeight={700} fontSize={'20px !important'} color={item.current_step ? '#003B6F' : '#8A9BB0'}>
                                    {StoreTranslate.UseLanguage({
                                        english: item.name.roman,
                                        native: item.name.native
                                    })}
                                </TypographyStyled>
                                {item.dc && (
                                    <TypographyStyled fontWeight={400} fontSize={'16px !important'} color={item.current_step ? '#003B6F' : '#8A9BB0'}>
                                        ● {item.dc}
                                    </TypographyStyled>
                                )}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
            <Box textAlign="center" borderTop={'1px solid #CCDEEF'} paddingTop={'32px'}>
                <TypographyStyled 
                    onClick={() => navigate(-1)} 
                    fontSize={'16px !important'} 
                    fontWeight={400} 
                    color="#003B6F" 
                    sx={{ 
                        cursor: "pointer", 
                        textDecorationLine: 'underline' 
                    }}
                >
                    {translate('back')}
                </TypographyStyled>
            </Box>
        </ThemeProvider>
    )
})

const TypographyStyled = styled(Typography)`
    /* font-family: Inter, Prompt; */
    /* font-family: Prompt, sans-serif; */
`

export default OrderTracking