import { MainTemplate } from 'Components/Template/MainTemplate'
import { Box, Container, Stack, Skeleton, ThemeProvider, Modal, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { lazy, Suspense, useEffect, useState } from 'react'
import pMinDelay from 'p-min-delay'
import Breadcrumb from 'Components/Elements/Checkout/Breadcrumb'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CartEmpty from './CartEmpty'
import { runInAction, toJS } from 'mobx'
import StoreFormat from 'GlobalStores/Format/StoreFormat'
import Checkout from 'GlobalStores/Checkout/Country/Checkout'
import { CreditCardHelper } from 'Helpers/CreditCardHelper'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ReactComponent as CheckedIcon } from '../Elements/Checkout/Assets/check-green.svg'
import { ReactComponent as TimesIcon } from '../Elements/Checkout/Assets/times.svg'
import Loader from '../Elements/Checkout/Assets/Loader.svg'
import { storeElement } from 'GlobalStores/StoreElement'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import Errors from 'Components/Elements/Errors/Errors'
import { DefaultButton } from 'Components/Elements/Forms'
import { get, isEmpty } from 'lodash'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import DefaultCheckoutTheme from './CheckoutThemes/DefaultCheckoutTheme'
import PhillipinesCheckoutTheme from './CheckoutThemes/PhilippinesCheckoutTheme'
import MoroccoCheckoutTheme from './CheckoutThemes/MoroccoCheckoutTheme'
import JordanCheckoutTheme from './CheckoutThemes/JordanCheckoutTheme'
import TaiwanCheckoutTheme from './CheckoutThemes/TaiwanCheckoutTheme'
import JapanCheckoutTheme from './CheckoutThemes/JapanCheckoutTheme'
import TurkiyeCheckoutTheme from './CheckoutThemes/TurkiyeCheckoutTheme'
import EgyptCheckoutTheme from './CheckoutThemes/EgyptCheckoutTheme'
import WorldPay from 'Components/Elements/Checkout/WorldPay/WorldPay'
import SouthAfricaCheckoutTheme from './CheckoutThemes/SouthAfricaCheckoutTheme'
import ThailandCheckoutTheme from './CheckoutThemes/ThailandCheckoutTheme'
import KuwaitCheckoutTheme from './CheckoutThemes/KuwaitCheckoutTheme'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import ReCaptchaV3 from 'Components/Elements/ReCaptcha/ReCaptchaV3'

const ShippingAddressBoxLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/ShippingAddress'), 0))
const PaymentBoxLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/Payment'), 0))
const PaymentReviewLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/PaymentReview'), 0))
const CreateAccountWithShippingLazyLoad = lazy(() => pMinDelay(import('../Elements/Checkout/CreateAccountWithShipping'), 0))

const CheckoutPage = observer(() => {
    const { width } = useSizeWindow()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const translate = useTranslate()
    const [openModal, setOpenModal] = useState(false)
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [openWorldPay, setOpenWorldPay] = useState(false)
    const [openBankWireModal, setOpenBankWireModal] = useState(false)

    const onPlaceOrder = async () => {
        try {
            StoreCheckout.checkoutLoading = false

            runInAction(() => {
                StoreCheckout.checkoutErrors = []
                storeElement.showCardFrontDrop = true
                StoreCheckout.checkoutLoading = true
            })

            const creditCard = {
                countryCode: StoreCountry.Country2(),
                creditCard: {
                    creditCardNumber: GetCardValue('card_number'),
                    payer: GetCardValue('name_on_card'),
                    creditCardExpires: GetCardValue('exp_date'),
                    creditCardSecurityCode: GetCardValue('cvv')
                }
            }

            const afterPassed = () => {
                setTimeout(() => {
                    runInAction(() => {
                        StoreCheckout.checkoutLoading = false
                        storeElement.showCardFrontDrop = true
                    })
                    navigate(`/checkout/review?uuid=${new URLSearchParams(location.search).get('uuid')}`)
                }, 1000)
            }

            const ProcessFormat = async () => {
                
                if (StoreCheckout.useBillingAddressSameAsShipping === false) {
                    let data = {
                        uuid: new URLSearchParams(location.search).get('uuid'),
                        ship_address_options: true
                    }
                    if (StoreCheckout.GetBillingAddressForm()) {
                        StoreCheckout.GetBillingAddressForm().map(item => {
                            if (item.key !== 'country') {
                                let key = item.key.toLowerCase()
                                if (key === 'zip_code') {
                                    key = 'zip'
                                }
                                if (key === 'street_address') {
                                    key = 'address'
                                }
                                data[`ship_address_${key}`] = item.value
                            }

                            return item
                        })

                        data['ship_address_fullname'] = StoreFormat.GetDetailPage().fullname
                        data['customer_main_address'] = StoreUser.CustomerData().Country()
                    }

                    const updateResponse = await FormatAPIs.putHotFormatV2(data, StoreCountry.Country3())
                    if (updateResponse.data.success) {
                        afterPassed()
                    } else {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = false
                            StoreCheckout.checkoutErrors = updateResponse.data.Message
                            StoreCheckout.checkoutLoading = false
                        })
                    }
                } else {
                    if (StoreFormat.GetDetailPage().ship_address_options) {
                        let data = {
                            uuid: new URLSearchParams(location.search).get('uuid'),
                            ship_address_options: false
                        }

                        const updateResponse = await FormatAPIs.putHotFormatV2(data, StoreCountry.Country3())
                        if (updateResponse.data.success) {
                            afterPassed()
                        } else {
                            runInAction(() => {
                                storeElement.showCardFrontDrop = false
                                StoreCheckout.checkoutErrors = updateResponse.data.Message
                                StoreCheckout.checkoutLoading = false
                            })
                        }
                    } else {
                        afterPassed()
                    }
                }
            }

            if (params.step === 'payment') {
                if (StoreCheckout.GetPaymentMethod() === 'bankwire') {
                    if (!StoreAuth.GetToken()) {
                        await StoreAuth.Login({ unicityID: StoreUser.baId, password: StoreUser.password })
                    }
                    ProcessFormat()
                } else {
                    const cardValidate = CreditCardHelper.cardValidate(creditCard)
                    // console.log('on place order cardValidate', cardValidate)
                    // if (cardValidate.success) {
                    //     if (!StoreAuth.GetToken()) {
                    //         await StoreAuth.Login({ unicityID: StoreUser.baId, password: StoreUser.password })
                    //     }
                    //     ProcessFormat()
                    // } else {
                    //     let cardErrors = []
                    //     cardValidate.error_messages.map(item => {
                    //         // cardErrors.push({
                    //         //     english: item,
                    //         //     native: item
                    //         // })
                    //         cardErrors.push(item)
                    //         return item
                    //     })
                    //     runInAction(() => {
                    //         storeElement.showCardFrontDrop = false
                    //         StoreCheckout.checkoutErrors = cardErrors
                    //         StoreCheckout.checkoutLoading = false
                    //     })
                    // }
                    if (!StoreAuth.GetToken()) {
                        await StoreAuth.Login({ unicityID: StoreUser.baId, password: StoreUser.password })
                    }
                    ProcessFormat()
                }
            } else {
                if (StoreCheckout.GetPaymentMethod() === 'bankwire' && ['JPN'].includes(StoreCountry.Country3())) {
                    setOpenBankWireModal(true)
                    runInAction(() => {
                        StoreCheckout.checkoutLoading = true
                        StoreCheckout.checkoutErrors = []
                        StorePaymentStep.errors = []
                    })
                    const checkout = new Checkout()
                    let items = []

                    StoreFormat.GetFormatData('formatProducts').map(item => {
                        items.push({
                            item_id: item.item_code,
                            item_name: item.item_name.english + ' ' + item.item_name.english,
                            price: item.baStatus === 'C' ? item.retail_price_x_qty.value : item.price_x_qty.value
                        })
                    })

                    const response = await checkout.SubmitBankwirePayment(StoreCheckout.GetUuid(), StoreFormat.GetDetailPage(), items)
                    if (response.success) {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = true
                            StoreCheckout.checkoutLoading = false
                        })
                        StoreCheckout.RestoreCart()
                        if (get(response, 'gateway_ref_id')) {
                            navigate(`/payment-response/success?gateway_ref_id=${response.gateway_ref_id}`)
                        } else {
                            navigate(`/payment-response/success?payment_id=${response.payment_id}`)
                        }
                    } else {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = false
                            StoreCheckout.checkoutLoading = false
                        })
                        setOpenBankWireModal(false)
                    }
                } else {
                    const paymentChannel = await StoreCheckout.CheckOutInstance().GetPaymentChannel()

                    if (paymentChannel === 'hydraWorldPay') {
                        setOpenWorldPay(true)
                    } else {
                        setOpenModal(true)
                    }

                    runInAction(() => {
                        StoreCheckout.checkoutErrors = []
                        StorePaymentStep.errors = []
                    })
                    const checkout = new Checkout()
                    let items = []

                    StoreFormat.GetFormatData('formatProducts').map(item => {
                        items.push({
                            item_id: item.item_code,
                            item_name: item.item_name.english + ' ' + item.item_name.english,
                            price: item.baStatus === 'C' ? item.retail_price_x_qty.value : item.price_x_qty.value
                        })
                    })

                    const creditCardData = checkout.CreditCardEncrypted(creditCard.creditCard)

                    // const allowCreditEnable =  StoreAuth.IsAllowCredit()

                    // if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                    //     creditCardData.amount = StoreCheckout.TotalPrice()
                    // }

                    const response = await checkout.SubmitPayment(
                        StoreCheckout.GetUuid(),
                        StoreFormat.GetDetailPage(),
                        creditCardData,
                        StoreCheckout.GetBillingAddressForm(),
                        items
                    )
                    if (response.success) {
                        runInAction(() => {
                            storeElement.showCardFrontDrop = true
                            StoreCheckout.checkoutLoading = false
                        })
                        setOpenModal(false)
                        setOpenWorldPay(false)
                        StoreCheckout.RestoreCart()
                        if (get(response, 'gateway_ref_id')) {
                            navigate(`/payment-response/success?gateway_ref_id=${response.gateway_ref_id}`)
                        } else {
                            navigate(`/payment-response/success?payment_id=${response.payment_id}`)
                        }
                    } else {
                        runInAction(() => {
                            StoreCheckout.checkoutLoading = false
                        })
                    }
                }
            }
        } catch (e) {
            console.log('error', e)
            runInAction(() => {
                storeElement.showCardFrontDrop = false
                StoreCheckout.checkoutLoading = false
            })
        }
    }

    const GetCardValue = key => {
        const card = StoreCheckout.creditCard.find(item => item.label === key)
        if (card) {
            return card.value
        }
        return ''
    }

    const setCompletedActionsLink = () => {
        runInAction(() => {
            const idx = StoreCheckout.CheckOutInstance()
                .GetBreadcrumbOption()
                .findIndex(e => e.link.includes(`/checkout/${params.step}`))

            const result = StoreCheckout.CheckOutInstance()
                .GetBreadcrumbOption()
                .reduce((acc, current, index) => {
                    if (index > idx) return acc
                    if (current.link === '/products') {
                        return [...acc, current.link]
                    }

                    return [...acc, `${current.link}?uuid=${StoreCheckout.GetUuid()}`]
                }, [])

            // StoreCheckout.completedActionsLink.push(`/checkout/${params.step}?uuid=${StoreCheckout.GetUuid()}`)

            StoreCheckout.completedActionsLink.push(...result)
        })
    }

    useEffect(() => {
        setCompletedActionsLink()
        StoreCheckout.checkoutErrors = []
        runInAction(() => {
            // storeElement.showCardFrontDrop = false
            StoreCheckout.checkoutErrors = []
            StoreCheckout.checkoutLoading = false
        })
        if (openModal === true) {
            setOpenModal(false)
        }

        return () => {
            StoreSubScriptions.SetIsCancelOldSubscription(null)
            StoreSubScriptions.SetClickOpenSubscriptionDetectModal(false)
            StoreSubScriptions.SetAbleToShowSubscriptionDetectModal(false)
            StoreSubScriptions.SetIsFinishSubscriptionDetectModal(false)
        }
    }, [])

    useEffect(() => {
        if (['payment', 'review'].includes(params.step)) {
            StoreCheckout.SetOrderSpecError([])
        }
    }, [params.step])

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0
                // behavior: 'smooth'
            })
        }, 1)
    }, [isEmpty(StoreCheckout.GetCart())])

    const PaymentStepTranslate = (key, step) => {
        let keyText = key
        if (key === 'payment') {
            keyText = 'loading_text_payment_data'
        } else if (key === 'createOrder') {
            keyText = 'loading_text_order_payment'
        } else if (key === 'updateLog') {
            keyText = 'loading_text_log'
        } else if (key === 'autoship') {
            keyText = 'loading_text_autoship'
        }

        const creatingText = 'loading_text_creating'
        const textSuccess = 'loading_text_success'
        const textFailed = 'loading_text_failed'

        switch (step) {
            case -1:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{keyText}</T> <T>{textFailed}</T>
                        </Typography>
                    </>
                )
            case 0:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{creatingText}</T> <T>{keyText}</T>
                        </Typography>
                    </>
                )
            case 1:
                return (
                    <>
                        <Typography fontSize={'24px'} color="#ffffff">
                            <T>{keyText}</T> <T>{textSuccess}</T>
                        </Typography>
                    </>
                )
            default:
                return <></>
        }
    }

    const ProcessingColor = step => {
        if (step === 1) {
            return 'process-success'
        } else if (step === -1) {
            return 'process-failed'
        }
    }

    const ProcessingIcon = step => {
        switch (step) {
            case 0:
                return <Spiner src={Loader} alt="Loading" style={{ marginLeft: 5 }} />
            case 1:
                return <CheckedIcon />
            case -1:
                return <TimesIcon />
            default:
                return <></>
        }
    }

    const Sections = step => {
        switch (step) {
            case 'payment':
                return <PaymentBoxLazyLoad onPlaceOrder={onPlaceOrder} />
            case 'review':
                return <PaymentReviewLazyLoad />
            case 'create-account':
                return <CreateAccountWithShippingLazyLoad />
            default:
                return <ShippingAddressBoxLazyLoad />
        }
    }

    const CheckoutThemesSelector = () => {
        if (StoreCountry.CountryLowerCase() === 'philippines') {
            return (
                <ReCaptchaV3>
                    <PhillipinesCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'jordan') {
            return (
                <ReCaptchaV3>
                    <JordanCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'morocco') {
            return (
                <ReCaptchaV3>
                    <MoroccoCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'taiwan') {
            return (
                <ReCaptchaV3>
                    <TaiwanCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'japan') {
            return (
                <ReCaptchaV3>
                    <JapanCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'turkiye') {
            return (
                <ReCaptchaV3>
                    <TurkiyeCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'egypt') {
            return (
                <ReCaptchaV3>
                    <EgyptCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'southafrica') {
            return (
                <ReCaptchaV3>
                    <SouthAfricaCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'thailand') {
            return (
                <ReCaptchaV3>
                    <StackStyled stepPaymentOrReview={params.step === 'review'}>
                        <ThailandCheckoutTheme
                            onPlaceOrder={onPlaceOrder}
                            step={params.step}
                            Sections={Sections}
                            setOpenLoginModal={setOpenLoginModal}
                            openLoginModal={openLoginModal}
                        />
                    </StackStyled>
                </ReCaptchaV3>
            )
        } else if (StoreCountry.CountryLowerCase() === 'kuwait') {
            return (
                <ReCaptchaV3>
                    <KuwaitCheckoutTheme
                        onPlaceOrder={onPlaceOrder}
                        step={params.step}
                        Sections={Sections}
                        setOpenLoginModal={setOpenLoginModal}
                        openLoginModal={openLoginModal}
                    />
                </ReCaptchaV3>
            )
        } else {
            return (
                <ReCaptchaV3>
                    <StackStyled stepPaymentOrReview={params.step === 'review'}>
                        <DefaultCheckoutTheme
                            onPlaceOrder={onPlaceOrder}
                            step={params.step}
                            Sections={Sections}
                            setOpenLoginModal={setOpenLoginModal}
                            openLoginModal={openLoginModal}
                        />
                    </StackStyled>
                </ReCaptchaV3>
            )
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Stack backgroundColor={MainTemplate.palette.background.default} minHeight="100vh">
                <ContainerStyled maxWidth="lg">
                    <Stack display={!isEmpty(StoreCheckout.GetCart()) ? 'inherit' : 'none'}>
                        <Suspense
                            fallback={
                                <Stack>
                                    <PageSkeleton isLogin={StoreAuth.IsAuthorized()} />
                                </Stack>
                            }>
                            <Stack>
                                <Box
                                    mt="40px"
                                    height="84px"
                                    sx={{
                                        marginBottom: {
                                            xs: 0,
                                            lg: '20px'
                                        }
                                    }}>
                                    <Breadcrumb breadcrumbOption={StoreCheckout.CheckOutInstance().GetBreadcrumbOption()} />
                                </Box>
                            </Stack>
                            {CheckoutThemesSelector()}
                        </Suspense>
                    </Stack>

                    <Stack display={isEmpty(StoreCheckout.GetCart()) ? 'block' : 'none'}>
                        <CartEmpty />
                    </Stack>
                </ContainerStyled>
            </Stack>
            <Modal
                disableEscapeKeyDown={true}
                open={openModal}
                onClose={() => setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Stack display={'flex'} alignItems={'center'} height="100%" justifyContent={'center'} padding={width < 730 ? '0 16px' : '0 47px'}>
                    <BackdropBodyStyled>
                        <Typography variant="h2" textAlign={'left'} fontSize={'30px'} color="#ffffff">
                            <T>hydra_payment_processing</T>
                        </Typography>
                        {StorePaymentStep.GetPaymentSteps() && (
                            <ProcessingStyled>
                                {StorePaymentStep.GetPaymentSteps().payment > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().payment)}>
                                        {PaymentStepTranslate('payment', StorePaymentStep.GetPaymentSteps().payment)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().payment)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().createOrder > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().createOrder)}>
                                        {PaymentStepTranslate('createOrder', StorePaymentStep.GetPaymentSteps().createOrder)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().createOrder)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().autoship > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().autoship)}>
                                        {PaymentStepTranslate('autoship', StorePaymentStep.GetPaymentSteps().autoship)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().autoship)}
                                    </li>
                                )}
                                {StorePaymentStep.GetPaymentSteps().updateLog > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().updateLog)}>
                                        {PaymentStepTranslate('updateLog', StorePaymentStep.GetPaymentSteps().updateLog)}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().updateLog)}
                                    </li>
                                )}
                            </ProcessingStyled>
                        )}
                        {StorePaymentStep.PaymentErrors().length > 0 ? <Errors list={StorePaymentStep.PaymentErrors()} /> : null}
                        {StorePaymentStep.PaymentErrors().length > 0 ? (
                            <Stack mt="40px" display={'flex'} alignItems={'center'}>
                                <Box width="40%">
                                    <DefaultButton
                                        titleText={translate('CLOSE')}
                                        onClick={() => {
                                            setOpenModal(false)
                                            runInAction(() => {
                                                storeElement.showCardFrontDrop = false
                                            })
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ) : null}
                    </BackdropBodyStyled>
                </Stack>
            </Modal>
            <Modal
                disableEscapeKeyDown={true}
                open={openBankWireModal}
                onClose={() => setOpenBankWireModal(!openBankWireModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Stack display={'flex'} alignItems={'center'} height="100%" justifyContent={'center'} padding={width < 730 ? '0 16px' : '0 47px'}>
                    <BackdropBodyStyled>
                        <Typography variant="h2" textAlign={'left'} fontSize={'30px'} color="#ffffff">
                            <T>hydra_payment_processing</T>
                        </Typography>
                        {StorePaymentStep.GetPaymentSteps() && (
                            <ProcessingStyled>
                                {StorePaymentStep.GetPaymentSteps().createOrder > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().createOrder)}>
                                        {PaymentStepTranslate('bankwire_createOrder', StorePaymentStep.GetPaymentSteps().createOrder)}{' '}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().createOrder)}
                                    </li>
                                )}

                                {StorePaymentStep.GetPaymentSteps().updateLog > -2 && (
                                    <li className={ProcessingColor(StorePaymentStep.GetPaymentSteps().updateLog)}>
                                        {PaymentStepTranslate('updateLog', StorePaymentStep.GetPaymentSteps().updateLog)}
                                        {ProcessingIcon(StorePaymentStep.GetPaymentSteps().updateLog)}
                                    </li>
                                )}
                            </ProcessingStyled>
                        )}
                        {StorePaymentStep.PaymentErrors().length > 0 ? <Errors list={StorePaymentStep.PaymentErrors()} /> : null}
                        {StorePaymentStep.PaymentErrors().length > 0 ? (
                            <Stack mt="40px" display={'flex'} alignItems={'center'}>
                                <Box width="40%">
                                    <DefaultButton
                                        titleText={translate('CLOSE')}
                                        onClick={() => {
                                            setOpenBankWireModal(false)
                                            runInAction(() => {
                                                storeElement.showCardFrontDrop = false
                                            })
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ) : null}
                    </BackdropBodyStyled>
                </Stack>
            </Modal>
            <WorldPay
                open={openWorldPay}
                onClose={() => {
                    setOpenWorldPay(false)
                }}
            />
        </ThemeProvider>
    )
})

const PageSkeleton = ({ isLogin = false }) => {
    return (
        <>
            <Stack>
                <Box
                    mt="40px"
                    height="84px"
                    sx={{
                        marginBottom: {
                            xs: 0,
                            lg: '20px'
                        }
                    }}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                        {StoreCheckout.CheckOutInstance()
                            .GetBreadcrumbOption()(StoreCheckout.CheckOutInstance().GetBreadcrumbOption() || [])
                            .map((_, index) => (
                                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} key={`breadcrumb-skeleton-${index}`}>
                                    <Box>
                                        <Skeleton variant="circular" width={'54px'} height={'54px'} sx={{ margin: '0' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={'10px'} sx={{ margin: '10px 0 10px 0' }} />
                                        <Skeleton variant="rounded" width={'100%'} height={'10px'} sx={{ margin: '10px 0 10px 0' }} />
                                    </Box>
                                    {index !== (StoreCheckout.CheckOutInstance().GetBreadcrumbOption() || []).length - 1 && (
                                        <Box
                                            width={
                                                (StoreCheckout.CheckOutInstance().GetBreadcrumbOption() || []).length < 4
                                                    ? { xs: '40px', sm: '120px', lg: '176px' }
                                                    : { xs: '0px', sm: '100px', lg: '176px' }
                                            }
                                            mx={3}>
                                            <Skeleton variant="rounded" width={'100%'} height={'5px'} sx={{ marginTop: '27px' }} />
                                        </Box>
                                    )}
                                </Box>
                            ))}
                    </Box>
                </Box>
            </Stack>
            <PageSkeletonFormElement isLogin={isLogin} />
        </>
    )
}

const PageSkeletonFormElement = ({ isLogin = false }) => (
    <StackStyled>
        <Stack>
            {!isLogin && <Skeleton variant="rounded" width={'100%'} height={'64px'} sx={{ margin: '0 0 20px 0' }} />}
            <Skeleton variant="rounded" width={'100%'} height={'500px'} sx={{ margin: '0' }} />
        </Stack>
        <Stack>
            <Skeleton variant="rounded" width={'100%'} height={'370px'} sx={{ margin: '0' }} />
        </Stack>
    </StackStyled>
)

const ContainerStyled = styled(Container)`
    @media screen and (min-width: 830px) and (max-width: 1200px) {
        padding: 0 70px;
    }
`

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 22px;
        padding-top: 40px;
    }
`

const BackdropBodyStyled = styled.div`
    padding: 40px 24px;
    background: #003b6f;
    border-radius: 8px;

    @media screen and (min-width: 1024px) {
        width: 650px;
    }
`

const ProcessingStyled = styled.ul`
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 0px;
    li {
        color: #ffffff;
        list-style-type: none;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: auto 35px;
        align-items: center;
        padding-left: 0px;

        svg {
            width: 35px;
        }

        &:last-child {
            padding-bottom: 0px;
        }

        &.process-success {
            color: #03c302;
        }

        &.process-failed {
            color: rgb(254, 93, 124);
        }
    }
`

const Spiner = styled('img')({
    animation: 'rotation 1s infinite linear',
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
})

export default CheckoutPage
