import { ReCAPTCHAV3_KEY } from 'Utils/Utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import storeReCaptcha from '../../../GlobalStores/StoreReCaptcha'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { reaction } from 'mobx'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const ReCaptchaV3 = observer(({ children }) => {

    const liveEnv = ['fg.unicity.com']
    const ReCaptChaKey = liveEnv.includes(window.location.hostname) ? ReCAPTCHAV3_KEY : ReCAPTCHAV3_KEY

    useEffect(() => {
        const disposer = reaction(
            () => StoreTranslate.CurrentLanguage(),
            currentLanguage => {
                storeReCaptcha.currentLanguage = currentLanguage
            }
        )

        storeReCaptcha.currentLanguage = StoreTranslate.CurrentLanguage() 

        return () => {
            disposer()
        }
    }, [])

    const HideReCaptchaBadge = () => {
        useEffect(() => {
          const style = document.createElement("style")
          style.innerHTML = ".grecaptcha-badge { visibility: hidden; }"
          document.head.appendChild(style)
        }, [])
      
        return null
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={ReCaptChaKey}>
            <HideReCaptchaBadge />
            {children}
        </GoogleReCaptchaProvider>
    )
})

export default ReCaptchaV3
