import { get } from 'lodash'
import { makeAutoObservable } from 'mobx'

class storeAdditionalProducts {
    products = []
    selectedProducts = []
    recalculating = false
    orderCal = {}

    GetHasAdditionalProducts() {
        return this.selectedProducts.length > 0
    }

    setProducts(products) {
        this.products = products
    }
    /**
     * Get product
     * @param {string} item_code
     * @returns {object}
     */
    getProduct(item_code) {
        return this.products.find(item => item.item_code === item_code)
    }
    /**
     * Remove product
     * @param {string} item_code
     */
    removeProduct(item_code) {
        this.selectedProducts = this.selectedProducts.filter(item => item.item.value !== item_code)
    }
    /**
     * Increase product
     * @param {string} item_code
     */
    increaseProduct(item_code) {
        const product = this.selectedProducts.find(item => item.item.value === item_code)
        if (product) {
            product.quantity += 1
        }
    }
    /**
     * Decrease product
     * @param {string} item_code
     */
    decreaseProduct(item_code) {
        const product = this.selectedProducts.find(item => item.item.value === item_code)
        if (product) {
            product.quantity -= 1
        }
    }
    /**
     * Get additional products total price
     * @returns {number}
     */
    getTotalPrice() {
        return this.selectedProducts.reduce((total, item) => {
            const product = this.getProduct(item.item.value)
            return total + product.price * item.quantity
        }, 0)
    }
    /**
     * Get orderCal subtotal
     * @returns {number}
     */
    getOrderCalSubtotal() {
        return get(this.orderCal, 'terms.subtotal', 0)
    }
    /**
     * Get orderCal total
     * @returns {number}
     */
    getOrderCalTotal() {
        return get(this.orderCal, 'terms.total', 0)
    }
    /**
     * Get item price from orderCal
     * @param {string} item_code
     * @param {string} key  'price' | 'priceEach'
     * @returns {number}
     */
    getTermsItemPrice(item_code, key = 'price') {
        let price = 0
        const item = this.orderCal.lines.items.find(item => item.item.id.unicity === item_code)
        if (item) {
            price = item.terms[key]
        }
        return price
    }
    /**
     * Set orderCal
     * @param {object} orderCal
     */
    setOrderCal(orderCal) {
        this.orderCal = orderCal[0] || orderCal || {}
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const StoreAdditionalProducts = new storeAdditionalProducts()
export default StoreAdditionalProducts
