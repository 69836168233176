import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom' 
import { toJS } from 'mobx'
import ChangePassword from './ChangePassword'
import EditEmail from './EditEmail' 
import Settings from './Settings'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import EditMobileNumber from './EditMobileNumber' 
// import PdpaSetting from './PdpaSetting'
import DeleteAccountSuccess from './DeleteAccount/DeleteAccountSuccess'
import PdpaSettingV2 from './PdpaSettingV2'

const PersonalDetailsRoute = observer(props => { 
    if (!StoreAuth.IsAuthorized()) {
        return <Navigate to={-1} replace />
    }
 
    return (
        <Routes>
            <Route index element={<Settings userData={toJS(StoreUser.userData)} />} />
            <Route path="settings" element={<Settings userData={toJS(StoreUser.userData)} />} />
            <Route path="email-edit" element={<EditEmail userData={toJS(StoreUser.userData)} />} />
            <Route path="mobile-number-edit" element={<EditMobileNumber userData={toJS(StoreUser.userData)} />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="pdpa-setting" element={<PdpaSettingV2 />} />
            <Route path="delete-account-success" element={<DeleteAccountSuccess />} />
        </Routes>
    )
})

export default PersonalDetailsRoute
