import { ThemeProvider } from '@mui/material'
import { styled } from '@mui/system'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { observer } from 'mobx-react-lite'
import SignUpElem from './../Elements/SignUp/SignUp'
import ReferralBar from 'Components/Elements/Referral/ReferralBar'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import ReCaptchaV3 from 'Components/Elements/ReCaptcha/ReCaptchaV3'

const SignUp = observer(() => {
    return (
        <ReCaptchaV3>
            <ThemeProvider theme={MainTemplate}>
                {!StoreAuth.isAuthorized && <ReferralBar />}
                <SignUpWrapper>
                    <SignUpElem />
                </SignUpWrapper>
            </ThemeProvider>
        </ReCaptchaV3>
    )
})

const SignUpWrapper = styled('div')`
    background-color: #d1d9e2;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 20px;

    @media screen and (min-width: 700px) {
        padding: 40px 70px 100px 70px;
    }
`

export default SignUp
