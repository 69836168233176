import { Box, Fade, Modal, Skeleton, Stack } from '@mui/material'
import { AlreadyAccount } from 'Components/Elements/SignUp/SignUp'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useState } from 'react'
import { lazy } from 'react'
import pMinDelay from 'p-min-delay'
import Checkout from 'GlobalStores/Checkout/Country/Checkout'
import styled from '@emotion/styled'
import Login from 'Components/Elements/Login/Login'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { DefaultButton } from 'Components/Elements/Forms'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import MoneyBack from 'Components/Elements/Checkout/MoneyBack'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import { storeElement } from 'GlobalStores/StoreElement'
import { runInAction } from 'mobx'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import ReCaptchaV3 from 'Components/Elements/ReCaptcha/ReCaptchaV3'

const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/PreSummaryBox'), 0))

const KuwaitShippingAddressLazyLoad = lazy(() =>
    pMinDelay(import('../../Elements/Checkout/ShippingAddress'), 0)
)

const PaymentBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/Payment'), 0))
const PaymentReviewLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/PaymentReview'), 0))
const CreateAccountWithShippingLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Kuwait/KuwaitCreateAccountWithShipping'), 0))

const KuwaitCheckoutTheme = observer(({ Sections, step, onPlaceOrder, openLoginModal, setOpenLoginModal }) => {
    const { width } = useSizeWindow()
    const translate = useTranslate()
    const navigate = useNavigate()
    const [sectionReady, setSectionReady] = useState(false)
    const [loading, setLoading] = useState(false)

    // const disabledExtendPlaceOrder = storeCheckout.GetPaymentMethod() !== 'bankwire'

    const notShowLogin = () => {
        return ['JPN'].includes(StoreCountry.Country3()) && StoreReferral.HasReferral()
    }

    const ThemeSections = step => {
        switch (step) {
            case 'payment':
                return <PaymentBoxLazyLoad />
            case 'review':
                return <PaymentReviewLazyLoad />
            case 'create-account':
                return <CreateAccountWithShippingLazyLoad />
            default:
                return <KuwaitShippingAddressLazyLoad sectionReady={setSectionReady} />
        }
    }

    // const submitPayment = async () => {
    //     try {
    //         storeCheckout.checkoutLoading = false

    //         runInAction(() => {
    //             storeCheckout.checkoutErrors = []
    //             storeElement.showCardFrontDrop = true
    //             storeCheckout.checkoutLoading = true
    //         })

    //         runInAction(() => ((storeCheckout.checkoutErrors = []), (StorePaymentStep.errors = [])))
    //         const checkout = new Checkout()
    //         let items = []

    //         storeFormat.GetFormatData('formatProducts').map(item => {
    //             items.push({
    //                 item_id: item.item_code,
    //                 item_name: item.item_name.english + ' ' + item.item_name.english,
    //                 price: item.baStatus === 'C' ? item.retail_price_x_qty.value : item.price_x_qty.value
    //             })
    //         })

    //         const response = await checkout.SubmitPayment(
    //             storeCheckout.GetUuid(),
    //             storeFormat.GetDetailPage(),
    //             '',
    //             storeCheckout.GetBillingAddressForm(),
    //             items
    //         )
    //         if (response.success) {
    //             runInAction(() => {
    //                 runInAction(() => (storeElement.showCardFrontDrop = true))
    //                 storeCheckout.checkoutLoading = false
    //             })
    //             storeCheckout.RestoreCart()
    //             if (get(response, 'gateway_ref_id')) {
    //                 navigate(`/payment-response/success?gateway_ref_id=${response.gateway_ref_id}`)
    //             } else {
    //                 navigate(`/payment-response/success?payment_id=${response.payment_id}`)
    //             }
    //         } else {
    //             runInAction(() => {
    //                 storeCheckout.checkoutLoading = false
    //             })
    //         }
    //     } catch (e) {
    //         console.log('error', e)
    //         runInAction(() => {
    //             storeElement.showCardFrontDrop = false
    //             storeCheckout.checkoutLoading = false
    //         })
    //     }
    // }

    const getPostBackUrlDEV = () => {
        const locationURL = window.location
        let pathname = ''

        if (/fg-dev/.test(window.location.href) && !/epic/.test(window.location.href)) {
            const subPath = locationURL.pathname.split('/')
            pathname = `${subPath[1]}/${subPath[2]}/${subPath[3]}`
        } else if (/epic/.test(window.location.href)) {
            const subPath = locationURL.pathname.split('/')
            pathname = `${subPath[1]}/${subPath[2]}/${subPath[3]}/${subPath[4]}/${subPath[5]}`
        }
        
        const postBackUrlDEV = {
            success: `{{hostname}}/${pathname}/{{country}}/payment-response/success`,
            error: `{{hostname}}/${pathname}/{{country}}/payment-response/failed`
        }
        
        const hostname = window.location
        const successUrlDEV = postBackUrlDEV.success.replace('{{hostname}}', hostname.origin).replace('{{country}}', StoreCountry.CountryLowerCase())
        const errorUrlDEV = postBackUrlDEV.error.replace('{{hostname}}', hostname.origin).replace('{{country}}', StoreCountry.CountryLowerCase())

        return {
            success: successUrlDEV,
            error: errorUrlDEV
        }
    }

    const redirectToMyFaTooRah = async () => {
        setLoading(true)
        try {
            storeCheckout.checkoutLoading = false

            runInAction(() => {
                storeCheckout.checkoutLoading = true
            })

            const hostname = window.location.origin
            const postbackUrl = {
                success: `{{hostname}}/{{country}}/payment-response/success`,
                error: `{{hostname}}/{{country}}/payment-response/failed`
            }
            const successUrl = postbackUrl.success.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())
            const errorUrl = postbackUrl.error.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())

            const ENV_DEV = /fg-dev/.test(window.location.href)

            const data = {
                uuid: storeCheckout.GetUuid(),
                type: 'feelGreat',
                token: storeAuth.GetToken(),
                ba_id: storeFormat.GetDetailPage().login_id,
                source_path: window.location.href,
                gateway_success_url: ENV_DEV ? getPostBackUrlDEV().success : successUrl,
                gateway_error_url: ENV_DEV ? getPostBackUrlDEV().error : errorUrl,
            }

            const response = await FeelGreatAPI.CreatePaymentDataForHostedGateway(data)

            if (response.data.success) {
                window.location.href = response.data.data.redirect_url
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                runInAction(() => {
                    storeCheckout.checkoutLoading = false
                })
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                runInAction(() => {
                    storeCheckout.checkoutLoading = false
                })
            }
        } catch (error) {
            runInAction(() => {
                storeCheckout.SetPaymentMethod('')
            })
            console.log('error', error)
            setLoading(false)
            runInAction(() => {
                storeCheckout.checkoutLoading = false
            })
        }
    }

    useEffect(() => {
        if (StoreLoader.IsFormatApiLoading() === true) {
            setSectionReady(false)
        }
    }, [StoreLoader.IsFormatApiLoading()])

    const extendPlaceOrder = () => {
        return (
            <Stack alignItems={'center'}>
                <DefaultButton
                    titleText={translate('proceed_to_myfatoorah')}
                    disabled={loading}
                    onClick={redirectToMyFaTooRah}
                    width={width > 700 ? '340px' : '100%'}
                    isloading={loading.toString()}
                    style={{ marginTop: '25px', fontSize: '18px' }}
                />
                <MoneyBack />
            </Stack>
        )
    }

    return (
        <PhStackStyled stepPaymentOrReview={step}>
            <Suspense fallback={<div></div>}>
                <Stack>{ThemeSections(step)}</Stack>
            </Suspense>

            <Stack width={width > 1200 ? '380px' : 'auto'}>
                {!sectionReady && StoreLoader.IsFormatApiLoading() ? (
                    <PageSkeletonSummaryElement />
                ) : (
                    <Suspense fallback={<PageSkeletonSummaryElement />}>
                        <PreSummaryBoxLazyLoad step={step} edit={true} isReady={storeCheckout.isReadyToPlaceOrder}
                            // onPlaceOrder={onPlaceOrder} 
                            extendPlaceOrder={extendPlaceOrder}
                        />
                    </Suspense>
                )}
            </Stack>
            {step === 'create-account' && (
                <>
                    <DesktopAlreadyAccount>{!notShowLogin() && <AlreadyAccount setOpenLoginModal={setOpenLoginModal} />}</DesktopAlreadyAccount>

                    <Modal
                        open={openLoginModal}
                        onClose={() => {
                            if (storeAuth.loadingLogin) return
                            setOpenLoginModal(false)
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Fade in={openLoginModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(50% - 40px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: '0px'
                                }}>
                                <Login showFooter={false} setOpenLoginModal={setOpenLoginModal} />
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
        </PhStackStyled>
    )
})

const PageSkeletonSummaryElement = observer(props => {
    return (
        <Stack>
            <Skeleton
                variant="rounded"
                width={'100%'}
                sx={{
                    margin: '0',
                    borderRadius: '12px',
                    height: '108px',
                    '@media screen and (min-width: 1200px)': {
                        height: '370px'
                    }
                }}
            />
        </Stack>
    )
})

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 22px;
        padding-top: 40px;
    }
`

const PhStackStyled = styled(StackStyled)`
    flex-direction: ${props => (props.stepPaymentOrReview === 'payment' ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};

    @media screen and (min-width: 768px) {
        padding-top: 40px;
        /* flex-direction: row;
        justify-content: center; */
        gap: 20px;
    }
`

const DesktopAlreadyAccount = styled('div')`
    display: block;
    @media screen and (min-width: 1200px) {
        display: none;
    }
`

export default KuwaitCheckoutTheme
