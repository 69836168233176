import { makeAutoObservable } from 'mobx'
import StoreUser from 'GlobalStores/User/StoreUser'
import { get } from 'lodash'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { PaymentAPIs } from './PaymentServices/PaymentAPI'
import { ServerEnv } from 'Configs/ServerEnv'
import { HydraAPIs } from 'Services/Hydra/HydraAPIs'
import StoreAdditionalProducts from './StoreAdditionalProducts'

const allowCountryList = ['PHL']

class StoreFreightCharge {
    freightCharge = 0

    GetFreightCharge = () => {
        return this.freightCharge
    }

    async SetFreightCharge() {
        if (!allowCountryList.includes(storeCountry.Country3())) {
            return
        }
        const customerData = Object.assign({}, StoreUser.CustomerData())
        const countryName = storeCountry.CountryRomanName()
        const currentDomain = window.location.origin
        const baId = storeAuth.GetId()
        const uShopData = {
            entryPeriod: get(customerData, 'userData.entryPeriod', ''),
            loginId: baId,
            period: StoreCheckout?.period || '',
            callFrom: `${currentDomain}/${countryName.toLowerCase()}`
        }

        const qs = new URLSearchParams(window.location.search)
        const uuid = qs.get('uuid')
        let orderDetail = null
        try {
            const hydraDetailResponse = await PaymentAPIs.CreatePaymentLambdaData(uuid)
            orderDetail = hydraDetailResponse?.data?.hydra
        } catch (error) {
            console.log('error >> SetFreightCharge hydraDetailResponse', error)
        }

        const order = {
            ...orderDetail,
            customer: {
                href: `${ServerEnv.Hydra()}/customers?unicity=${baId}`
            }
        }

        const reqBody = {
            uShopData,
            order
        }

        const token = storeAuth.GetToken()
        const orderTermsResponse = await HydraAPIs.HydraOrderTerms(reqBody, token)
       
        const freightChargeAmount = Number(orderTermsResponse.data.items[0].terms?.freight?.amount)

        if (typeof freightChargeAmount === 'number') {
            this.freightCharge = freightChargeAmount
        } else {
            this.freightCharge = 0
        }

        StoreAdditionalProducts.setOrderCal(orderTermsResponse.data.items)

        return
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const storeFreightCharge = new StoreFreightCharge()
export default storeFreightCharge
