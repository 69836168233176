import axios from "axios"
import httpHelper from "Services/HttpHelper"

export const verifyTokenRecaptchaV3 = (googleToken) => {
    const url = `${httpHelper.Proxy({
        originalUrl: `https://member-calls2.unicity.com/bot-protection/recaptchaV3/verify`
    })}`

    const data = {
        // is_bot: true, // for test bot
        // is_random_score: true, // for test bot
        g_token: googleToken
    }

    return httpHelper.Post({
        url: url,
        data,
    })
}