import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Card, Stack } from '@mui/material'
import OrderTracking from './OrderTracking'
import styled from "@emotion/styled";
import { ReactComponent as RefreshIcon } from './Assets/RefreshIcon.svg'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import storeAuth from 'GlobalStores/User/StoreAuth'

const OrderTrackingPage = observer((props) => {
    const [dataTracking, setDataTracking] = useState(null)
    // 31512d2a1d4a2a5860bc785d27d1f7523f4cddebe7273b8e499193244925f504
    const qsOrderRef = new URLSearchParams(window.location.search).get('orderRef')

    const getTrackingData = async () => {
        try {
            const response = await FeelGreatAPI.GetOrderHistoryDetail(storeAuth.GetToken(), qsOrderRef)
            if (response) {
                setDataTracking(response.data.ushop?.ushopTrackResult?.data[0])
            }
        } catch(e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (qsOrderRef) {
            getTrackingData()
        }
    }, [qsOrderRef])

  return (
    <Stack 
        className='container'
        sx={{ 
            alignItems: 'center',
            padding: '32px 16px',
            '@media screen and (min-width: 600px)': {
                padding: '32px 40px 40px 40px',
            }
        }}
    >
        {dataTracking ? ( 
            <Box 
                sx={{ 
                    width: '100%', 
                    maxWidth: '772px',
                }}
            >
                <Stack 
                    sx={{ 
                        background: '#FFFFFF',
                        borderRadius: '12px',
                        padding: '32px 24px', 
                    }}
                >
                    <OrderTracking dataTracking={dataTracking}/>
                </Stack>
            </Box>
        ) : (
            <Box 
                sx={{ 
                    width: '100%', 
                    maxWidth: '772px',
                    minHeight: '800px',
                    margin: '30px auto 60px auto',
                    background: "#FFFFFF", 
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconSpinner />
            </Box>
        )}
    </Stack>
  )
})

const IconSpinner = styled(RefreshIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 65px;
    height: 65px;

    circle {
        stroke: #e2e8ed;
    }
    path {
        stroke: #e2e8f2;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`


export default OrderTrackingPage