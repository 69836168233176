import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Button, Paper, styled, Stack } from '@mui/material'
import { updatePDPA } from './../../../Services/FeelGreat/APIs'
import { ReactComponent as Spinner2 } from './Assets/Spinner2.svg'
import { ReactComponent as SpinnerSuccess } from './Assets/SpinnerSuccess.svg'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeUser from 'GlobalStores/User/StoreUser'
import { get, last } from 'lodash'
import storeAuth from 'GlobalStores/User/StoreAuth'

const PdpaSettingV2 = observer(() => {
    const [selectedValue, setSelectedValue] = useState(null)
    const [loading, setLoading] = useState({ pending: false, success: false })
    const translate = useTranslate()

    const handleChange = event => {
        setSelectedValue(event.target.value)
        setLoading({ pending: false, success: false })
    }

    const handleConfirm = async() => {
        setLoading({ pending: true, success: false })    
    
        try {
            const customerHref = last(get(storeUser, 'userData.href', '').split('/'))
            const value = selectedValue === 'true' ? 1 : 0
            const token = storeAuth.GetToken()

            const response = await updatePDPA(customerHref, value, token)

            if(response) {
                setTimeout(() => {
                    setLoading({ pending: false, success: true })
                }, 1000)
            }
            
        } catch(err) {
            console.error(err)
            setLoading({ pending: false, success: false })
        }
    }

    const radioItems = [
        {
            id: 1,
            value: 'true',
            label: 'text_options_agree_pdpa',
        },
        {
            id: 2,
            value: 'false',
            label: 'text_options_dont_agree_pdpa'
        }
    ]
    
    return (
            <Paper 
                sx={{ 
                    width: 'auto', 
                    maxWidth: '854px', 
                    borderRadius: '12px', 
                    boxShadow: 'none',
                }}
            >
                <TypographyStyled fontSize={'14px'} mb={'16px'}>
                    {translate('th_pdpa_agreement')}
                </TypographyStyled>
                <TypographyStyled fontSize={'32px'} lineHeight={'40px'} fontWeight={600} mb={'25px'}>
                    {translate('th_pdpa_acceptance')}
                </TypographyStyled>
                <TypographyStyled fontSize={'18px'} fontWeight={400} mb={'32px'} letterSpacing={'0.5px'} sx={{ fontFamily: 'Inter, NotoSansThai' }}>
                    {translate('desciption_pdpa')}
                </TypographyStyled>

                <RadioGroup value={selectedValue} sx={{ gap: '16px' }} onChange={handleChange}>
                    {radioItems.map((item, id) => {
                        const isSelected = selectedValue === item.value

                        return (
                            <FormControlLabel 
                                key={id}
                                disabled={loading.pending}
                                value={item.value} 
                                sx={{ 
                                    cursor: 'pointer',
                                    outline: isSelected ? '2px solid #99BCDF' : '1px solid #CCDEEF',
                                    borderRadius: '8px', 
                                    margin: 0, 
                                    backgroundColor: isSelected ? '#F4F8FB' : '#FFFFFF',
                                    padding: '17px 16px',
                                    transition: 'opacity 0.3s ease',
                                    opacity: loading.pending ? 0.5 : 1,
                                    '@media screen and (min-width: 600px)': {
                                        padding: '17px 24px',
                                    },
                                }} 
                                control={
                                    <Radio sx={{ 
                                        padding: 0,
                                        '& .MuiSvgIcon-root': { 
                                            fontSize: 20,
                                        },
                                        color: '#CCDEEF',
                                        '&.Mui-checked': { 
                                            color: '#003B6F',
                                        },
                                    }}
                                />} 
                                label={
                                    <TypographyStyled 
                                        sx={{ 
                                            fontFamily: 'Inter, NotoSansThai',
                                            fontSize: '16px', 
                                            color: isSelected ? '#003B6F' : '#5A8FC3', 
                                            fontWeight: isSelected ? 600 : 400,
                                            marginLeft: '12px',
                                            '@media screen and (min-width: 600px)': {
                                                marginLeft: '24px',
                                            }
                                        }}
                                    >
                                        {translate(item.label)}
                                    </TypographyStyled>
                                } 
                            />
                        )
                    })}
                </RadioGroup>

                <Stack 
                    sx={{ 
                        width: '100%',
                        alignItems: 'center',
                        '@media screen and (min-width: 900px)': {
                            alignItems: 'flex-start',
                        }
                    }}
                >
                    <Button
                        sx={{ 
                            marginTop: '32px',
                            width: '100%',
                            maxWidth: '342px',
                            background: '#5A8FC3', 
                            borderRadius: '99px', 
                            height: '55px',
                            textTransform: 'capitalize',
                            '&:disabled': {
                                background: '#CCDEEF',
                                color: '#FFFFFF',
                            },
                            '&:hover': {
                                background: '#5A8FC3',
                                color: '#FFFFFF'
                            }
                        }} 
                        onClick={handleConfirm}
                        disabled={(!selectedValue || loading.pending)}  
                        disableRipple 
                    >
                        <TypographyStyled sx={{ fontSize: '26px', fontWeight: 700, color: '#FFFFFF', marginRight: '12px' }}>
                            {translate('confirm')}
                        </TypographyStyled>

                        {loading.pending && <IconSpinner />}

                        {loading.success && <SpinnerSuccess />}
                    </Button>
                </Stack>
            </Paper>
    )
})

const TypographyStyled = styled(Typography)`
    font-family: poppins, NotoSansThai;
    font-style: normal;
    color: #003B6F;
`

export const IconSpinner = styled(Spinner2)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 30px;
    height: 30px;

    circle {
        stroke: #e2e8ed;
    }
    path {
        stroke: #e2e8f2;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default PdpaSettingV2
