import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { storeElement } from 'GlobalStores/Cores/Elements/StoreElement'
import { Box, Stack, Typography, styled, Skeleton, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { runInAction, toJS } from 'mobx'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { get, last } from 'lodash'
import { formatPriceDemical, useSizeWindow } from 'Helpers/GlobalHelpers'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreProducts from 'GlobalStores/StoreProducts'
import { isNothing } from 'Utils/Utils'
import moment from 'moment'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'

const OrderHistoryDetails = observer(props => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const translate = useTranslate()
    const { width } = useSizeWindow()
    const navigate = useNavigate()

    useEffect(() => {
        GetDetail(props.dataOrderRef)

        runInAction(() => {
            storeElement.settingCard.title = width > 834 ? '' : <T>order_history</T>
            storeElement.settingCard.titleAlign = 'center'
            // storeElement.settingCard.padding = '0 15px'
            storeElement.settingCard.opacity = 'none'
            storeElement.userLayout.className = 'feelgreat--orderhistory'
        })

        return () => {
            runInAction(() => {
                storeElement.settingCard.title = ''
                storeElement.settingCard.titleAlign = ''
                storeElement.settingCard.padding = ''
                storeElement.userLayout.className = ''
                storeElement.settingCard.opacity = ''
            })
        }
    }, [])

    const GetDetail = async dataOrderRef => {
        setLoading(true)
        if (dataOrderRef) {
            const resp = await FeelGreatAPI.GetOrderHistoryDetail(StoreAuth.GetToken(), dataOrderRef.href)

            if (resp) {
                setData(resp.data.ushop)
            } else {
                setLoading(false)
            }
        }
        setLoading(false)
    }

    function toMonthName(monthNumber) {
        const date = new Date()
        date.setMonth(monthNumber - 1)

        return date.toLocaleString('en-US', {
            month: 'short'
        })
    }

    function getDate() {
        const dateCreate = get(data, 'dateCreated', '')
        if (!dateCreate) return '-'
        return moment(dateCreate).format('DD')
    }

    function getYear() {
        const dateCreate = get(data, 'dateCreated', '')
        if (!dateCreate) return '-'
        return moment(dateCreate).format('YYYY')
    }

    const dataPriceShipping = formatPriceDemical(get(data, 'terms.freight.amount', ''))

    const productCreditData = () => {
        if (get(data, 'transactions', 0) !== 0) {
            const transactions = get(data, 'transactions.items', 0)
            const productCredit = transactions.filter(item => item.method === 'StoreCredit')
            if (productCredit.length > 0) {
                return {
                    isShow: true,
                    price: productCredit[0].amount
                }
            }
        }
        return {
            isShow: false,
            price: 0
        }
    }

    const productCredit = productCreditData()

    const onClickTracking = () => {
        const countryToOrderTrackingPage = ['TH']
        const countryIsToExternalUrl = ['']
        const countryNotRedirect = ['']
        
        if (!countryIsToExternalUrl.includes(storeCountry.Country2()) && !countryNotRedirect.includes(storeCountry.Country2())) {
            return navigate(`/order-tracking?orderRef=${last(props.dataOrderRef.href.split('/'))}`)
        } else if (countryIsToExternalUrl.includes(storeCountry.Country2())) {
            return
        } else if (countryNotRedirect.includes(storeCountry.Country2())) {
            return
        }
    }

    return (
        <OrderHistoryStyled loading={loading ? 1 : 0}>
            {!loading && Object.keys(data).length !== 0 ? (
                <>
                    {/* header */}
                    <OrderHistoryTitle>
                        <Typography variant="caption" fontWeight={300} color="#003B6F" lineHeight="21px">
                            <T>order_number</T>:&nbsp;{data.id.unicity.split('-')[1]}
                        </Typography>
                        <Typography variant="caption" fontWeight={300} color="#003B6F" lineHeight="21px">
                            {`${getDate()} ${toMonthName(data.dateCreated.split('-')[1])} ${getYear()}`}
                        </Typography>
                    </OrderHistoryTitle>

                    <Stack padding={width > 834 ? '30px 40px' : '20px 15px'}>
                        {/* product */}
                        {data.lines.items.map(product => {
                            return (
                                <OrderHistoryProducts
                                    key={product.item.id.unicity}
                                    product={product}
                                    currency={data.currency}
                                    itemID={product.item.id.unicity}
                                />
                            )
                        })}

                        {/* detail */}
                        <OrderHistorySummary>
                            <OrderHistorySummaryRow
                                columnLeft={`${translate('ship_to')} :`}
                                columnRight={get(data, 'shipToName.fullName', '')}
                                variant={'h5'}
                                fontWeightShipTo={600}
                                fontWeightRight={300}
                                fontSize={width > 834 ? '18px' : '16px'}
                                lineHeight={'21.78px'}
                                margin={'0 0 0 0'}
                            />
                            <OrderHistorySummaryRow
                                columnRight={`${get(data, 'shipToAddress.address1', '')} ${get(data, 'shipToAddress.address2', '')}`}
                                variant={'caption'}
                                fontWeight={300}
                                margin={'10px 0 0 0'}
                                lineHeight={'21.78px'}
                                color={'#003B6F'}
                            />
                            <OrderHistorySummaryRow
                                columnRight={`${get(data, 'shipToAddress.city', '')} ${get(data, 'shipToAddress.country', '')} ${get(
                                    data,
                                    'shipToAddress.zip',
                                    ''
                                )}`}
                                variant={'caption'}
                                fontWeight={300}
                                lineHeight={'16.94px'}
                                color={'#003B6F'}
                                margin={'0 0 0 0'}
                            />
                            <OrderHistorySummaryRow
                                columnLeft={translate('shipping')}
                                columnRight={dataPriceShipping == 0 ? translate('free') : `${dataPriceShipping} ${data.currency}`}
                                variant={'h5'}
                                fontWeight={300}
                                fontSize={width > 834 ? '18px' : '16px'}
                                lineHeight={'21.78px'}
                                margin={'20px 0 0 0'}
                            />
                            {/* product credit */}
                            {productCredit.isShow && (
                                <OrderHistorySummaryRow
                                    columnLeft={translate('od_product_credit')}
                                    columnRight={`-${formatPriceDemical(productCredit.price)} ${data.currency}`}
                                    variant={'h5'}
                                    fontWeight={300}
                                    fontSize={width > 834 ? '18px' : '16px'}
                                    lineHeight={'21.78px'}
                                    margin={'20px 0 0 0'}
                                />
                            )}
                            {data.ushopTrackResult?.data[0] && (
                                <OrderHistorySummaryRow
                                    columnLeft={translate('tracking_number')}
                                    columnRight={data.ushopTrackResult?.data[0]?.responseObject?.do_no}
                                    variant={'h5'}
                                    fontWeight={300}
                                    fontSize={width > 834 ? '18px' : '16px'}
                                    lineHeight={'21.78px'}
                                    margin={'20px 0 0 0'}
                                    conponent={'a'}
                                    propsRight={{ 
                                        sx: { 
                                            color: '#5A8FC3', 
                                            textDecorationLine: 'underline', 
                                            cursor: 'pointer' 
                                        }, 
                                        onClick: () => onClickTracking()
                                    }}
                                />
                            )}
                            <OrderHistorySummaryRow
                                columnLeft={translate('order_total')}
                                columnRight={`${formatPriceDemical(get(data, 'terms.total', 0))} ${data.currency}`}
                                variant={'h3'}
                                fontWeight={600}
                                fontSize={width > 834 ? '24px' : '20px'}
                                lineHeight={'29px'}
                                color={'#003B6F'}
                                margin={'20px 0 0 0'}
                            />
                        </OrderHistorySummary>
                    </Stack>
                    <ButtonBack />
                </>
            ) : (
                <SkeletonLoading />
            )}
        </OrderHistoryStyled>
    )
})

const OrderHistorySummaryRow = observer(props => {
    return (
        <OrderHistorySummaryRowStyled>
            <Typography
                textAlign={'left'}
                fontWeight={props.fontWeight || props.fontWeightShipTo}
                fontSize={props.fontSize}
                color={props.color}
                lineHeight={props.lineHeight}
                variant={props.variant}
                margin={props.margin}>
                {props.columnLeft}
            </Typography>

            <Typography
                width="60%"
                textAlign={'right'}
                fontWeight={props.fontWeight || props.fontWeightRight}
                fontSize={props.fontSize}
                color={props.color}
                lineHeight={props.lineHeight}
                variant={props.variant}
                margin={props.margin}
                component={props.component}
                {...props.propsRight}>
                {props.columnRight}
            </Typography>
        </OrderHistorySummaryRowStyled>
    )
})

const OrderHistoryProducts = observer(props => {
    const [productImage, setProductImg] = useState(null)

    const fallbackImage = 'https://s3-ap-southeast-1.amazonaws.com/ushop-media.unicity.com/images/product/unicitylogo.jpg'

    const renderProductImg = () => {
        const ITEM_ID = props.itemID
        // const ITEM_ID = '34533'

        const itemProduct = toJS(StoreProducts.GetProductByItemCode(ITEM_ID))
        const NoIMG = isNothing(itemProduct)

        NoIMG ? setProductImg(fallbackImage) : setProductImg(itemProduct?.image_url)
    }

    useEffect(() => {
        renderProductImg()
    }, [])

    return (
        <>
            <OrderHistoryProductsStyled>
                <Box className="box-img-order-history-products" marginLeft={'10px'}>
                    <img
                        src={productImage}
                        alt={'error'}
                        onError={e => {
                            e.target.src = fallbackImage
                        }}
                    />
                </Box>

                <Box className="box-details-order-history-products">
                    <Stack className="stack-details-order-history-products">
                        {/* left column */}
                        <Typography width={'65%'} variant="h5" color="#003B6F" fontWeight={600} className="title-product">
                            {props.product.catalogSlide.content.description}
                        </Typography>

                        {/* right column */}
                        <Typography variant="h5" color="#003B6F" fontWeight={600} className="price-product" textAlign="right">
                            {formatPriceDemical(props.product.terms.priceEach)} {props.currency}
                        </Typography>
                    </Stack>

                    <Stack mt="10px">
                        <Typography variant="label" fontWeight={300} fontSize="12px" color="#6687A2">
                            <T>qty</T>&nbsp;: {props.product.quantity}
                        </Typography>
                    </Stack>
                </Box>
            </OrderHistoryProductsStyled>
        </>
    )
})

const ButtonBack = observer(props => {
    const navigate = useNavigate()
    const { width } = useSizeWindow()
    return (
        <Box textAlign="center" cursor="pointer" padding="30px" margin={width > 834 ? '0 40px' : '0 15px'} borderTop="1px solid #d1d9e2">
            <Typography variant="link" sx={{ fontWeight: '300', fontSize: '14px', color: '#003B6F' }} onClick={() => navigate(-1)}>
                <T>back</T>
            </Typography>
        </Box>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <Stack width={'90%'}>
            <Stack gap={'20px'}>
                <Skeleton variant="rounded" width={'auto'} height={40} />
                <Skeleton variant="rounded" width={'auto'} height={89} />
                <Skeleton variant="rounded" width={'auto'} height={89} />
            </Stack>
            <Divider sx={{ marginTop: '20px' }} />

            <Skeleton variant="text" sx={{ fontSize: '30px', marginTop: '20px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
        </Stack>
    )
})

const OrderHistoryStyled = styled(Stack)`
    text-align: left;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(14, 35, 56, 0.2);
    width: 100%;
    display: flex;
    /* justify-content:  ${props => (props.loading ? 'center' : '')}; */
    align-items: ${props => (props.loading ? 'center' : '')};
    height: ${props => (props.loading ? 'auto' : '')};
    padding: ${props => (props.loading ? '30px 0' : '')};

    @media screen and (min-width: 900px) {
        height: ${props => (props.loading ? 'auto' : 'auto')};
    }
`

const OrderHistoryTitle = styled(Box)`
    background: #ecf2f9;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 15px;
    justify-content: space-between;
    display: flex;

    @media screen and (min-width: 900px) {
        padding: 20px 40px;
    }
`

const OrderHistoryProductsStyled = styled(Box)`
    width: 100%;
    display: flex;
    border-bottom: 1px solid #d1d9e2;
    padding-bottom: 20px;

    :not(:first-of-type) {
        padding-top: 20px;
    }

    .box-img-order-history-products {
        display: flex;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: transparent;
        object-fit: contain;
        object-position: center;
    }

    .box-details-order-history-products {
        width: 100%;
        margin-left: 20px;

        .stack-details-order-history-products {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title-product,
            .price-product {
                font-size: 16px;
            }
        }
    }
`

const OrderHistorySummary = styled(Box)`
    margin-top: 20px;
`
const OrderHistorySummaryRowStyled = styled(Box)`
    display: flex;
    justify-content: space-between;
`

export default OrderHistoryDetails
