import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get } from 'lodash'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import storeAuth from './User/StoreAuth'
import storeCountry from './Cores/County/StoreCountry'
import StoreProducts from './StoreProducts'
// import moment from 'moment'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout';

class storeSubScriptions {
    subscriptionAll = []
    subscriptionsList = []
    nextDateDelivery = ''
    selectedFlavor = ''
    flavorItemCode = ''
    qty = 0
    price = 0
    dateOfmonth = ''
    forms = []
    shippingForms = []
    creditCard = []
    fontSize = ''
    orderSpec = null
    scrollY = 0

    isReadyToPlaceOrder = false

    isCancelOldSubscription = null
    isClickOpenSubscriptionDetectModal = false
    ableToShowSubscriptionDetectModal = false
    countryImplementWarningList = ['SGP']
    isFinishSubscriptionDetectModal = false

    Init(data) {
        Object.keys(data).map(key => {
            runInAction(() => {
                if (key === 'shippingAddress') {
                    this.forms = data[key].inputs
                    this.shippingForms = data[key].inputs
                }
                if (key === 'order') {
                    this.orderSpec = data[key]
                }
            })

            return true
        })
    }

    IsReadyToPlaceOrder() {
        let allFilled = []
        this.creditCard.map(item => {
            if (item.value.length > 0) {
                allFilled.push(true)
            } else {
                allFilled.push(false)
            }
            return item
        })

        this.isReadyToPlaceOrder = !ObjectHelpers.hasFalseElements(allFilled)
    }

    SetCreditCard(itemToSet, value) {
        this.creditCard.map(item => {
            if (item.key === itemToSet.key) {
                if (value.length === 0) {
                    itemToSet.value = ''
                } else {
                    if (item.args) {
                        const regx = get(itemToSet, 'args.regx', null)
                        let regexPass = false
                        if (regx) {
                            const regex = new RegExp(regx, 'i')
                            if (regex.test(value)) {
                                regexPass = true
                            }
                        } else {
                            regexPass = true
                        }

                        const maxLength = get(itemToSet, 'args.maxLength', null)
                        let maxLengthPass = false
                        if (maxLength) {
                            if (value.length <= maxLength) {
                                maxLengthPass = true
                            }
                        } else {
                            maxLengthPass = true
                        }

                        if (regexPass && maxLengthPass) {
                            item.value = value
                        }
                    } else if (itemToSet.key === 'exp_date') {
                        // item.value = moment(value).format('MM/YY')
                        item.value = [value.month, value.year.slice(2, value.year.length)].join('/')
                    } else {
                        item.value = value
                    }
                }
            }

            return item
        })
        this.IsReadyToPlaceOrder()
    }

    SetCheckoutFormValue(key, value) {
        this.forms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
        this.shippingForms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
    }

    SetSelectedFlavor(value) {
        runInAction(() => {
            this.selectedFlavor = value
        })
    }

    SetFlavorPrice(value) {
        runInAction(() => {
            this.price = value
        })
    }

    SetSubscriptionsList(value) {
        runInAction(() => {
            this.subscriptionsList = value
        })
    }

    SetFlavorQTY(value) {
        runInAction(() => {
            this.qty = value
        })
    }

    SetFlavorItemCode(value) {
        runInAction(() => {
            this.flavorItemCode = value
        })
    }

    SetDateOfmonth(value) {
        runInAction(() => {
            this.dateOfmonth = value
        })
    }

    //get
    GetCreditCardForm() {
        return this.creditCard
    }

    GetCheckoutForms() {
        return this.forms
    }

    GetShippingForm() {
        return this.shippingForms
    }

    GetFlavorItemCode() {
        return this.flavorItemCode
    }

    GetFlavorQTY() {
        return this.qty
    }

    GetFlavorPrice() {
        return this.price
    }

    GetSelectedFlavor() {
        return this.selectedFlavor
    }

    GetSubscriptionsList() {
        return this.subscriptionsList
    }

    GetNextDateDelivery() {
        return this.nextDateDelivery
    }

    GetDateOfmonth() {
        return this.dateOfmonth
    }

    GetSubscriptionAll() {
        return this.subscriptionAll
    }

    SetSubscriptionAll(value) {
        runInAction(() => {
            this.subscriptionAll = value
        })
    }

    async GetHydraAutoOrders() {

        const response = await FeelGreatAPI.GetSubscriptions(storeAuth.GetToken())

        if (response) {
            const filterData = await Promise.all(
                response.data.items
                    .filter(item => item.market === storeCountry.Country2())
                    .map(async data => {
                        let useData = { ...data }

                        let jpStyle = null
                        if (useData.market === 'JP') {
                            jpStyle = await FeelGreatAPI.GetSubscriptionsItems(storeAuth.GetToken(), data.href)
                            useData = {
                                ...useData,
                                lines: jpStyle?.data?.lines,
                                recurrence: jpStyle?.data?.recurrence,
                                shipToAddress: jpStyle?.data?.shipToAddress,
                                transactions: jpStyle?.data?.transactions,
                                terms: jpStyle?.data?.terms,
                                shippingMethod: jpStyle?.data?.shippingMethod,
                                shipToEmail: jpStyle?.data?.shipToEmail
                            }
                        }
                        return {
                            ...useData,
                            productItem: useData.lines.items
                                .map(lineItem => {
                                    let createdItem = {
                                        ...StoreProducts.GetProductByItemCode(lineItem.item.id.unicity),
                                        quantity: lineItem.quantity
                                    }

                                    return createdItem
                                })
                                .filter(createdItem => Object.keys(createdItem).length > 1)
                        }
                    })
            )


            runInAction(() => {
                this.SetSubscriptionAll(filterData)
            })

            return filterData

        } else {
            runInAction(() => {
                this.SetSubscriptionAll([])
            })
            return []
        }
    }

    SetIsCancelOldSubscription(value) {
        runInAction(() => {
            this.isCancelOldSubscription = value
        })
    }

    GetIsCancelOldSubscription() {
        return this.isCancelOldSubscription
    }

    async HandleAbleToShowSubscriptionDetectModal() {

        const haveSubscriptionInCart = StoreCheckout.CheckSubscribeInCart()

        const isCountryImplement = this.countryImplementWarningList.includes(storeCountry.Country3())

        if (!haveSubscriptionInCart || !isCountryImplement) {
            this.ableToShowSubscriptionDetectModal = false
            return
        }

        await this.GetHydraAutoOrders()

        const haveOldSubscriptionOrder = this.GetSubscriptionAll().length > 0

        this.ableToShowSubscriptionDetectModal = haveOldSubscriptionOrder && isCountryImplement && haveSubscriptionInCart

        return
    }

    SetClickOpenSubscriptionDetectModal(value) {
        runInAction(() => {
            this.isClickOpenSubscriptionDetectModal = value
        })
    }

    GetClickOpenSubscriptionDetectModal() {
        return this.isClickOpenSubscriptionDetectModal
    }

    GetAbleToShowSubscriptionDetectModal() {
        return this.ableToShowSubscriptionDetectModal
    }

    SetIsFinishSubscriptionDetectModal(value) {
        runInAction(() => {
            this.isFinishSubscriptionDetectModal = value
        })
    }

    SetAbleToShowSubscriptionDetectModal(value) {
        runInAction(() => {
            this.ableToShowSubscriptionDetectModal = value
        })
    }

    HandleWaitingSubscriptionDetectModal() {

        return new Promise((resolve, reject) => {
            let isUserActionFinish = false

            const waitingForFinishSubscriptionDetectModal = setInterval(() => {

                if (this.isFinishSubscriptionDetectModal && isUserActionFinish === false) {

                    isUserActionFinish = true
                    this.SetIsFinishSubscriptionDetectModal(false)
                    resolve()
                    clearInterval(waitingForFinishSubscriptionDetectModal)
                }
            }, 1000)
        })

    }

    constructor() {
        makeAutoObservable(this)
    }
}
const StoreSubScriptions = new storeSubScriptions()
export default StoreSubScriptions