import { Box, Fade, Modal, Skeleton, Stack } from '@mui/material'
import { AlreadyAccount } from 'Components/Elements/SignUp/SignUp'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useState } from 'react'
import Login from '../../Elements/Login/Login'
import { lazy } from 'react'
import pMinDelay from 'p-min-delay'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import styled from '@emotion/styled'
import MoneyBack from 'Components/Elements/Checkout/MoneyBack'
import { DefaultButton } from 'Components/Elements/Forms'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import PaymentForm from 'Components/Elements/Checkout/ShippingAddressForm/Country/Morocco/PaymentForm'
import { isSomething } from 'Utils/Utils'
import Checkout from 'GlobalStores/Checkout/Country/Checkout'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import { get } from 'lodash'

import { useNavigate } from 'react-router-dom'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { toJS } from 'mobx'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'

import PaymentHydraForm from 'Components/Elements/Checkout/ShippingAddressForm/Country/PaymentHydraForm'
import ReCaptchaV3 from 'Components/Elements/ReCaptcha/ReCaptchaV3'

const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/PreSummaryBox'), 0))
const EgyptCreateAccountWithShippingLazyLoad = lazy(() =>
    pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Egypt/EgyptCreateAccountWithShipping'), 0)
)
const EgyptShippingAddressLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Egypt/EgyptShippingAddress'), 0))

const EgyptPaymentLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/Egypt/EgyptPayment'), 0))

const EgyptCheckoutTheme = observer(({ Sections, step, onPlaceOrder, openLoginModal, setOpenLoginModal }) => {
    const translate = useTranslate()
    const { width } = useSizeWindow()
    const [dataPayment, setDataPayment] = useState({})
    const [loading, setLoading] = useState(false)
    const [sectionReady, setSectionReady] = useState(false)

    const shippingPaymentOption = StoreCheckout.GetShippingPaymentOption()

    const [dataRedirectToPayment, setDataRedirectToPayment] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(false)
    }, [])

    const ThemeSections = step => {
        switch (step) {
            case 'payment':
                return <EgyptPaymentLazyLoad onPlaceOrder={onPlaceOrder} sectionReady={setSectionReady} />
            case 'create-account':
                return <EgyptCreateAccountWithShippingLazyLoad sectionReady={setSectionReady} />
            default:
                return <EgyptShippingAddressLazyLoad sectionReady={setSectionReady} />
        }
    }

    const submitRedirectPayment = async () => {
        setLoading(true)
        if (StoreCheckout.CheckOutInstance().Instance().PaymentGateway === 'hydra3ds') {
            const checkout = new Checkout()
            let items = []
            storeFormat.GetFormatData('formatProducts').map(item => {
                items.push({
                    item_id: item.item_code,
                    item_name: item.item_name.english + ' ' + item.item_name.english,
                    price: item.baStatus === 'C' ? item.retail_price_x_qty.value : item.price_x_qty.value
                })
            })

            try {
                const response = await checkout.SubmitPayment(
                    StoreCheckout.GetUuid(),
                    storeFormat.GetDetailPage(),
                    {},
                    StoreCheckout.GetBillingAddressForm(),
                    items,
                    {
                        shippingPaymentOption: StoreCheckout.GetShippingPaymentOption(),
                        totalPrice: StoreCheckout.TotalPrice()
                    }
                )
                if (StoreCheckout.GetShippingPaymentOption()?.id === 2) {
                    storeCheckout.ClearCart()
                    navigate(`/payment-response/success?payment_id=${response.payment_id}`)
                } else {
                    if (response.orderData.transactions.items.length > 1) {
                        const dataPrepareToForm = response.orderData.transactions.items.find(item => item.method === 'CreditCard')
                        if (dataPrepareToForm) {
                            setDataRedirectToPayment({
                                formMethod: get(dataPrepareToForm, 'methodDetails.iouReceipt.method'),
                                formAction: get(dataPrepareToForm, 'methodDetails.iouReceipt.url'),
                                formData: get(dataPrepareToForm, 'methodDetails.iouReceipt.data')
                            })
                        }
                    } else {
                        setDataRedirectToPayment({
                            formMethod: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.method') || 'POST',
                            formAction: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.url'),
                            formData: get(response.orderData, 'transactions.items[0].methodDetails.iouReceipt.data')
                        })
                    }
                }
            } catch (e) {
                console.log('error', e)
            }
        }
    }

    const extendPlaceOrder = () => {
        return (
            <Stack alignItems={'center'}>
                <DefaultButton
                    titleText={translate('proceed_to_payment')}
                    disabled={loading || shippingPaymentOption === null}
                    onClick={e => {
                        submitRedirectPayment()
                    }}
                    width={width > 700 ? '340px' : '100%'}
                    isloading={loading.toString()}
                    style={{ marginTop: '25px', fontSize: '18px' }}
                />
                <MoneyBack />
            </Stack>
        )
    }

    useEffect(() => {
        if (StoreLoader.IsFormatApiLoading() === true) {
            setSectionReady(false)
        }
    }, [StoreLoader.IsFormatApiLoading()])

    return (
        <MarStackStyled stepPaymentOrReview={step}>
            <Suspense fallback={<PageSkeletonFormElement />}>
                <Stack>{ThemeSections(step)}</Stack>
            </Suspense>

            {/* {!sectionReady && <PageSkeletonFormElement />} */}

            <Stack width={width > 1200 ? '380px' : 'auto'}>
                {!sectionReady && StoreLoader.IsFormatApiLoading() ? (
                    <PageSkeletonSummaryElement />
                ) : (
                    <Suspense fallback={<PageSkeletonSummaryElement />}>
                        <PreSummaryBoxLazyLoad
                            step={step}
                            edit={false}
                            isReady={StoreCheckout.isReadyToPlaceOrder}
                            onPlaceOrder={onPlaceOrder}
                            extendPlaceOrder={extendPlaceOrder}
                        />
                    </Suspense>
                )}
            </Stack>

            {step === 'create-account' && (
                <>
                    {StoreLoader.IsFormatApiLoading() ? (
                        <PageSkeletonAccountElement />
                    ) : (
                        <DesktopAlreadyAccount>
                            <AlreadyAccount setOpenLoginModal={setOpenLoginModal} />
                        </DesktopAlreadyAccount>
                    )}
                    <Modal
                        open={openLoginModal}
                        onClose={() => {
                            if (StoreAuth.loadingLogin) return
                            setOpenLoginModal(false)
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Fade in={openLoginModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 'calc(50% - 40px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    padding: '0px'
                                }}>
                                <Login showFooter={false} setOpenLoginModal={setOpenLoginModal} />
                            </Box>
                        </Fade>
                    </Modal>
                </>
            )}
            {isSomething(dataPayment) && (
                <div style={{ display: 'none' }}>
                    <PaymentForm dataPayment={dataPayment} />
                </div>
            )}
            {dataRedirectToPayment && (
                <div style={{ display: 'inherit' }}>
                    <PaymentHydraForm dataPayment={dataRedirectToPayment} />
                </div>
            )}
        </MarStackStyled>
    )
})

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 22px;
        padding-top: 40px;
    }
`

const MarStackStyled = styled(StackStyled)`
    flex-direction: ${props => (props.stepPaymentOrReview === 'payment' ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};

    @media screen and (min-width: 768px) {
        padding-top: 40px;
        gap: 20px;
    }
`

const DesktopAlreadyAccount = styled('div')`
    display: block;
    @media screen and (min-width: 1200px) {
        display: none;
    }
`

const PageSkeletonFormElement = observer(props => {
    return (
        <Stack>
            <Stack mb="20px">
                <Skeleton variant="rounded" width={'100%'} height={'340px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
            <Stack mb="20px">
                <Skeleton variant="rounded" width={'100%'} height={'400px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
            <Stack>
                <Skeleton variant="rounded" width={'100%'} height={'400px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
        </Stack>
    )
})

const PageSkeletonAccountElement = observer(props => {
    return (
        <Stack
            sx={{
                display: 'block',
                '@media screen and (min-width: 1200px)': {
                    display: 'none'
                }
            }}>
            <Skeleton variant="rounded" width={'100%'} height={'64px'} sx={{ margin: '0', borderRadius: '12px' }} />
        </Stack>
    )
})

const PageSkeletonSummaryElement = observer(props => {
    return (
        <Stack>
            <Skeleton
                variant="rounded"
                width={'100%'}
                sx={{
                    margin: '0',
                    borderRadius: '12px',
                    height: '108px',
                    '@media screen and (min-width: 1200px)': {
                        height: '370px'
                    }
                }}
            />
        </Stack>
    )
})

export default EgyptCheckoutTheme
