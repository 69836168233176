import { Skeleton, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Suspense, useEffect, useState } from 'react'
import { lazy } from 'react'
import pMinDelay from 'p-min-delay'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import styled from '@emotion/styled'
import MoneyBack from 'Components/Elements/Checkout/MoneyBack'
import { DefaultButton } from 'Components/Elements/Forms'
import { useSizeWindow } from 'Helpers/GlobalHelpers'

import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import ReCaptchaV3 from 'Components/Elements/ReCaptcha/ReCaptchaV3'

const PreSummaryBoxLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/PreSummaryBox'), 0))
const SouthAfricaCreateAccountWithShippingLazyLoad = lazy(() =>
    pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/SouthAfrica/SouthAfricaCreateAccountWithShipping'), 0)
)
const SouthAfricaShippingAddressLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/SouthAfrica/SouthAfricaShippingAddress'), 0))

const SouthAfricaPaymentReviewLazyLoad = lazy(() => pMinDelay(import('../../Elements/Checkout/ShippingAddressForm/Country/SouthAfrica/SouthAfricaPaymentReview'), 0))

const SouthAfricaCheckoutTheme = observer(({ Sections, step, onPlaceOrder, openLoginModal, setOpenLoginModal }) => {
    const translate = useTranslate()
    const { width } = useSizeWindow()
    const [loading, setLoading] = useState(false)
    const [sectionReady, setSectionReady] = useState(false)

    useEffect(() => {
        setLoading(false)
    }, [])

    const ThemeSections = step => {
        switch (step) {
            case 'review':
                return <SouthAfricaPaymentReviewLazyLoad extendPlaceOrder={extendPlaceOrder} sectionReady={setSectionReady} />
            case 'create-account':
                return <SouthAfricaCreateAccountWithShippingLazyLoad sectionReady={setSectionReady} />
            default:
                return <SouthAfricaShippingAddressLazyLoad sectionReady={setSectionReady} />
        }
    }

    const extendPlaceOrder = () => {
        return (
            <Stack alignItems={'center'}>
                <DefaultButton
                    titleText={translate('confirm_payment')}
                    disabled={loading}
                    onClick={onPlaceOrder}
                    width={width > 700 ? '340px' : '100%'}
                    isloading={loading.toString()}
                    style={{ marginTop: '50px', fontSize: '18px' }}
                />
                <MoneyBack />
            </Stack>
        )
    }

    useEffect(() => {
        if (StoreLoader.IsFormatApiLoading() === true) {
            setSectionReady(false)
        }
    }, [StoreLoader.IsFormatApiLoading()])

    return (
        <MarStackStyled stepPaymentOrReview={step}>
            <Suspense fallback={<PageSkeletonFormElement />}>
                <Stack>{ThemeSections(step)}</Stack>
            </Suspense>

            <Stack width={width > 1200 ? '380px' : 'auto'}>
                {!sectionReady && StoreLoader.IsFormatApiLoading() ? (
                    <PageSkeletonSummaryElement />
                ) : (
                    <Suspense fallback={<PageSkeletonSummaryElement />}>
                        <PreSummaryBoxLazyLoad
                            step={step}
                            edit={false}
                            isReady={StoreCheckout.isReadyToPlaceOrder}
                        />
                    </Suspense>
                )}
            </Stack>
        </MarStackStyled>
    )
})

const StackStyled = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 50vh;
    display: flex;
    flex-direction: ${props => (props.stepPaymentOrReview ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};
    gap: 20px;

    @media screen and (min-width: 399px) {
        padding-top: 40px;
    }

    @media screen and (min-width: 600px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 770px 370px;
        gap: 22px;
        padding-top: 40px;
    }
`

const MarStackStyled = styled(StackStyled)`
    flex-direction: ${props => (props.stepPaymentOrReview === 'payment' ? 'column' : 'column-reverse')};
    justify-content: ${props => (props.stepPaymentOrReview ? 'flex-start' : 'flex-end')};

    @media screen and (min-width: 768px) {
        padding-top: 40px;
        gap: 20px;
    }
`

const DesktopAlreadyAccount = styled('div')`
    display: block;
    @media screen and (min-width: 1200px) {
        display: none;
    }
`

const PageSkeletonFormElement = observer(props => {
    return (
        <Stack>
            <Stack mb="20px">
                <Skeleton variant="rounded" width={'100%'} height={'340px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
            <Stack mb="20px">
                <Skeleton variant="rounded" width={'100%'} height={'400px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
            <Stack>
                <Skeleton variant="rounded" width={'100%'} height={'400px'} sx={{ margin: '0', borderRadius: '12px' }} />
            </Stack>
        </Stack>
    )
})

const PageSkeletonAccountElement = observer(props => {
    return (
        <Stack
            sx={{
                display: 'block',
                '@media screen and (min-width: 1200px)': {
                    display: 'none'
                }
            }}>
            <Skeleton variant="rounded" width={'100%'} height={'64px'} sx={{ margin: '0', borderRadius: '12px' }} />
        </Stack>
    )
})

const PageSkeletonSummaryElement = observer(props => {
    return (
        <Stack>
            <Skeleton
                variant="rounded"
                width={'100%'}
                sx={{
                    margin: '0',
                    borderRadius: '12px',
                    height: '108px',
                    '@media screen and (min-width: 1200px)': {
                        height: '370px'
                    }
                }}
            />
        </Stack>
    )
})

export default SouthAfricaCheckoutTheme
